import { PRICING_PATH } from 'src/utils/urls';

export const translations = {
  'en-ca': {
    'card::content-block::corePdp::currency': '{{AUM_THRESHOLD_CORE}}',
    'card::content-block::premiumPdp::currency': '{{AUM_THRESHOLD_PREMIUM}}',
    'card::content-block::generationPdp::currency': '{{AUM_THRESHOLD_GENERATION}}',
    'card::content-block::core::currency': '{{AUM_THRESHOLD_CORE}}',
    'card::content-block::premium::currency': '{{AUM_THRESHOLD_PREMIUM}}',
    'card::content-block::generation::currency': '{{AUM_THRESHOLD_GENERATION}}',
    'card::content-block::post-text': 'in assets',
    'pricing::benefit-cards': [
      {
        id: 'core',
        heading: 'Core',
        subheading: 'Get started with simple, low-fee financial tools.',
        infoLists: [
          {
            heading: 'Benefits',
            items: [
              '$0 commission stock trading',
              '{{MANAGED_INVEST_FEE_CORE}} management fees on managed investing accounts',
              '{{CASH_INTEREST_RATE_CORE}} interest on your Cash account balance',
            ],
          },
        ],
        ctaLabel: `Download app`,
      },
      {
        id: 'premium',
        heading: 'Premium',
        subheading: 'Make the most of your investments with lower fees and tailored advice.',
        infoLists: [
          {
            heading: 'Benefits',
            items: [
              '{{MANAGED_INVEST_FEE_PREMIUM}} management fees on managed investing accounts',
              '{{CASH_INTEREST_RATE_PREMIUM}} interest on your Cash account balance',
              'Complimentary USD accounts & reduced options trading and crypto fees',
              'Financial check-ins with an advisor',
            ],
          },
        ],
      },
      {
        id: 'generation',
        heading: 'Generation',
        subheading: 'Build your legacy with expert guidance from your dedicated team of advisors.',
        infoLists: [
          {
            heading: 'Benefits',
            items: [
              '{{MANAGED_INVEST_FEE_GENERATION_MIN}}*–{{MANAGED_INVEST_FEE_GENERATION_MAX}} management fees on managed investing accounts',
              '{{CASH_INTEREST_RATE_GENERATION}} interest on your Cash account balance',
              'Comprehensive financial planning with a dedicated advisor',
              '10 airport lounge passes a year',
            ],
          },
        ],
      },
    ],
    'pricing::pdp-cards::managed-investing': [
      {
        id: 'core',
        eyebrow: '{{AUM_THRESHOLD_CORE}} in assets',
        heading: 'Core',
        subheading: 'Get started with simple, low-fee financial tools.',
        infoLists: [
          {
            items: ['Automatic portfolio rebalancing', 'Help from a real, friendly, human'],
          },
        ],
        fxRate: '{{MANAGED_INVEST_FEE_CORE}}',
        fxText: 'management fee',
      },
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} in assets',
        heading: 'Premium',
        subheading: 'Make the most of your investments with lower fees and tailored advice.',
        infoLists: [
          {
            items: [
              '20% lower management fee on your managed investments',
              'Financial goal setting with an advisor',
            ],
          },
        ],
        fxRate: '{{MANAGED_INVEST_FEE_PREMIUM}}',
        fxText: 'management fee',
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} in assets',
        heading: 'Generation',
        subheading: 'Build your legacy with expert guidance from your dedicated team of advisors.',
        infoLists: [
          {
            items: ['All Core and Premium features', 'Dedicated team of advisors'],
          },
        ],
        fxRate: '{{MANAGED_INVEST_FEE_GENERATION_MIN}}*–{{MANAGED_INVEST_FEE_GENERATION_MAX}}',
        fxText: 'management fee',
      },
    ],
    'pricing::pdp-cards::options': [
      {
        id: 'core',
        eyebrow: '{{AUM_THRESHOLD_CORE}} in assets',
        heading: 'Core',
        subheading: 'Get started with simple, low-fee financial tools.',
        infoLists: [
          {
            items: [
              '$0 commission stock trading',
              'Up to {{INSTANT_DEPOSIT_MAX_CORE}} in instant deposits',
            ],
          },
        ],
        fxRate: '{{OPTIONS_CONTRACT_PRICE_CORE}} USD',
        fxText: 'per contract',
      },
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} in assets',
        heading: 'Premium',
        subheading: 'Make the most of your investments with lower fees and tailored advice.',
        infoLists: [
          {
            items: [
              'Complimentary USD accounts',
              'No commission or FX fees on U.S. trades',
              'Up to {{INSTANT_DEPOSIT_MAX}} in instant deposits',
            ],
          },
        ],
        fxRate: '{{OPTIONS_CONTRACT_PRICE_PREMIUM}} USD',
        fxText: 'per contract',
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} in assets',
        heading: 'Generation',
        subheading: `Build your legacy with expert guidance from your dedicated team of advisors.`,
        infoLists: [
          {
            items: ['All Core and Premium features', 'Comprehensive financial plan'],
          },
        ],
        fxRate: '{{OPTIONS_CONTRACT_PRICE_GENERATION}} USD',
        fxText: 'per contract',
      },
    ],
    'pricing::pdp-cards::trade': [
      {
        id: 'core',
        eyebrow: '{{AUM_THRESHOLD_CORE}} in assets',
        heading: 'Core',
        subheading: 'Get started with simple, low-fee financial tools.',
        infoLists: [
          {
            items: [
              '$0 commission stock trading',
              'Up to {{INSTANT_DEPOSIT_MAX_CORE}} in instant deposits',
            ],
          },
        ],
        fxRate: '{{USD_ACCOUNT_FX_RATE_CORE}}',
        fxText: 'FX fee per trade',
      },
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} in assets',
        heading: 'Premium',
        subheading: 'Make the most of your investments with lower fees and tailored advice.',
        infoLists: [
          {
            items: [
              'Complimentary USD accounts',
              'No commission or FX fees on U.S. trades',
              'Up to {{INSTANT_DEPOSIT_MAX}} in instant deposits',
            ],
          },
        ],
        fxRate: '{{USD_ACCOUNT_FX_RATE_PREMIUM}}',
        fxText: 'FX fee per trade',
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} in assets',
        heading: 'Generation',
        subheading: `Build your legacy with expert guidance from your dedicated team of advisors.`,
        infoLists: [
          {
            items: ['All Core and Premium features', 'Comprehensive financial plan'],
          },
        ],
        fxRate: '{{USD_ACCOUNT_FX_RATE_GENERATION}}',
        fxText: 'FX fee per trade',
      },
    ],
    'pricing::pdp-cards::spend': [
      {
        id: 'core',
        eyebrow: '{{AUM_THRESHOLD_CORE}} in assets',
        heading: 'Core',
        subheading: 'Get started with simple, low-fee financial tools.',
        infoLists: [
          {
            items: [
              '{{DIY_TRADING_COMMISSION_FEE}} commission stock trading',
              '{{MANAGED_INVEST_FEE_CORE}} management fees on managed investing accounts',
              'Client support when you need it',
            ],
          },
        ],
        fxRate: '{{CASH_INTEREST_RATE_CORE}}',
        fxText: 'Cash account interest',
      },
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} in assets',
        heading: 'Premium',
        subheading: 'Make the most of your investments with lower fees and tailored advice.',
        infoLists: [
          {
            items: [
              'All the Core client benefits',
              '{{MANAGED_INVEST_FEE_PREMIUM}} management fees on managed investing accounts',
              'Goal setting with an advisor ',
            ],
          },
        ],
        fxRate: '{{CASH_INTEREST_RATE_PREMIUM}}',
        fxText: 'Cash account interest',
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} in assets',
        heading: 'Generation',
        subheading: `Build your legacy with expert guidance from your dedicated team of advisors.`,
        infoLists: [
          {
            items: [
              'All Premium benefits',
              '{{MANAGED_INVEST_FEE_GENERATION_MIN}}*—{{MANAGED_INVEST_FEE_GENERATION_MAX}} management fees on managed investing accounts',
              'Dedicated team of advisors',
            ],
          },
        ],
        fxRate: '{{CASH_INTEREST_RATE_GENERATION}}',
        fxText: 'Cash account interest',
      },
    ],
    'pricing::pdp-cards::crypto': [
      {
        id: 'core',
        eyebrow: '{{AUM_THRESHOLD_CORE}} in assets',
        heading: 'Core',
        subheading: 'Get started with simple, low-fee financial tools.',
        infoLists: [
          {
            items: ['Safe coin storage', `No fees to deposit or withdraw`],
          },
        ],
        fxRate: '{{CRYPTO_TRANSACTION_FEE_CORE}}',
        fxText: 'trading fees',
      },
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} in assets',
        heading: 'Premium',
        subheading: 'Make the most of your investments with lower fees and tailored advice.',
        infoLists: [
          {
            items: ['All Core plan benefits', 'Lower trading fees per transaction'],
          },
        ],
        fxRate: '{{CRYPTO_TRANSACTION_FEE_PREMIUM}}',
        fxText: 'trading fees',
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} in assets',
        heading: 'Generation',
        subheading: `Build your legacy with expert guidance from your dedicated team of advisors.`,
        infoLists: [
          {
            items: ['All Core and Premium benefits', 'Higher yields on staked coins'],
          },
        ],
        fxRate: '{{CRYPTO_TRANSACTION_FEE_GENERATION}}',
        fxText: 'trading fees',
      },
    ],
    'pricing::pdp-cards::tax': [
      {
        id: 'basic',
        heading: 'Basic',
        subheading: 'Every powerful feature you need to file with confidence.',
        infoLists: [
          {
            items: [
              'Guarantees your maximum refund',
              'Optimizes credits and deductions for the best result',
            ],
          },
        ],
        fxRate: 'Pay what you want',
        fxText: 'Yes, even {{TAX_TIER_PRICE_BASIC}}',
      },
      {
        id: 'plus',
        heading: 'Plus',
        subheading: 'Priority email support and audit protection for all of your returns.',
        infoLists: [
          {
            items: ['All the Basic benefits', 'Audit protection'],
          },
        ],
        fxRate: '{{TAX_TIER_PRICE_PLUS}}',
        fxRatePost: '+ tax',
        fxText: `Free for [Premium](${PRICING_PATH}) clients`,
      },
      {
        id: 'pro',
        heading: 'Pro',
        subheading: `Enhance your experience with a one-on-one consultation from a tax expert.*`,
        infoLists: [
          {
            items: ['All the Plus benefits', 'Customized advice'],
          },
        ],
        fxRate: '{{TAX_TIER_PRICE_PRO}}',
        fxRatePost: '+ tax',
        fxText: `Free for [Generation](${PRICING_PATH}) clients`,
      },
    ],
  },
  'fr-ca': {
    'card::content-block::post-text': 'd’actifs',
    'pricing::benefit-cards': [
      {
        id: 'core',
        heading: 'Essentiel',
        subheading: 'Commencez avec des outils financiers simples et à peu de frais.',
        infoLists: [
          {
            heading: 'Vous obtenez',
            items: [
              'Opérations boursières sans commission',
              'Frais de gestion de {{MANAGED_INVEST_FEE_CORE}} sur les comptes placements gérés',
              '{{CASH_INTEREST_RATE_CORE}} d’intérêts sur votre compte Cash',
            ],
          },
        ],
      },
      {
        id: 'premium',
        heading: 'Avantage',
        subheading:
          'Tirez le maximum de vos investissements grâce à des frais réduits et des conseils personnalisés.',
        infoLists: [
          {
            heading: 'Vous obtenez',
            items: [
              'Frais de gestion de {{MANAGED_INVEST_FEE_PREMIUM}} sur les comptes placements gérés',
              '{{CASH_INTEREST_RATE_PREMIUM}} d’intérêts sur votre compte Cash',
              'Comptes USD gratuits et frais réduits sur la négociation d’options et de crypto',
              'Séances d’évaluation financière avec notre équipe-conseil',
            ],
          },
        ],
      },
      {
        id: 'generation',
        heading: 'Génération',
        subheading: 'Bâtissez votre patrimoine grâce aux conseils avisés de notre équipe-conseil.',
        infoLists: [
          {
            heading: 'Vous obtenez',
            items: [
              'Frais de gestion de {{MANAGED_INVEST_FEE_GENERATION_MIN}}* - {{MANAGED_INVEST_FEE_GENERATION_MAX}} sur les comptes placements gérés',
              '{{CASH_INTEREST_RATE_GENERATION}} d’intérêts sur votre compte Cash',
              'Planification financière complète avec votre conseiller.ère',
              '10 accès à des salons aéroportuaires par année',
            ],
          },
        ],
      },
    ],
    'pricing::pdp-cards::managed-investing': [
      {
        id: 'core',
        eyebrow: '{{AUM_THRESHOLD_CORE}} d’actifs',
        heading: 'Essentiel',
        subheading: 'Commencez avec des outils financiers simples et à peu de frais.',
        infoLists: [
          {
            items: [
              'Rééquilibrage automatique du portefeuille',
              'L’aide d’un vrai humain sympathique',
            ],
          },
        ],
        fxRate: '{{MANAGED_INVEST_FEE_CORE}}',
        fxText: 'Frais de gestion',
      },
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} d’actifs',
        heading: 'Avantage',
        subheading:
          'Tirez le maximum de vos placements grâce à des frais réduits et à des conseils sur mesure.',
        infoLists: [
          {
            items: [
              'Frais de gestion réduits de 20 % sur vos placements gérés',
              'Planification financière avec un.e conseiller.ère',
            ],
          },
        ],
        fxRate: '{{MANAGED_INVEST_FEE_PREMIUM}}',
        fxText: 'Frais de gestion',
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} d’actifs',
        heading: 'Génération',
        subheading: `Bâtissez votre patrimoine grâce aux conseils avisés de votre équipe-conseil attitrée.`,
        infoLists: [
          {
            items: [
              'Toutes les caractéristiques d’Essentiel et d’Avantage',
              'Équipe-conseil attitrée',
            ],
          },
        ],
        fxRate: '{{MANAGED_INVEST_FEE_GENERATION_MIN}}* - {{MANAGED_INVEST_FEE_GENERATION_MAX}}',
        fxText: 'Frais de gestion',
      },
    ],
    'pricing::pdp-cards::options': [
      {
        id: 'core',
        eyebrow: '{{AUM_THRESHOLD_CORE}} d’actifs',
        heading: 'Essentiel',
        subheading: 'Commencez avec des outils financiers simples et à peu de frais.',
        infoLists: [
          {
            items: [
              'Opérations boursières sans commission',
              'Jusqu’à {{INSTANT_DEPOSIT_MAX_CORE}} en dépôts instantanés',
            ],
          },
        ],
        fxRate: '{{OPTIONS_CONTRACT_PRICE_CORE}} USD',
        fxText: 'par contrat',
      },
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} d’actifs',
        heading: 'Avantage',
        subheading:
          'Tirez le maximum de vos placements grâce à des frais réduits et à des conseils sur mesure.',
        infoLists: [
          {
            items: [
              'Comptes $ US',
              'Aucune commission sur les frais de change pour les opérations aux États-Unis',
            ],
          },
        ],
        fxRate: '{{OPTIONS_CONTRACT_PRICE_PREMIUM}} USD',
        fxText: 'par contrat',
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} d’actifs',
        heading: 'Génération',
        subheading: `Bâtissez votre patrimoine grâce aux conseils avisés de votre équipe-conseil attitrée.`,
        infoLists: [
          {
            items: [
              'Toutes les caractéristiques d’Essentiel et d’Avantage',
              'Plan financier complet',
              'Jusqu’à {{INSTANT_DEPOSIT_MAX}} en dépôts instantanés',
            ],
          },
        ],
        fxRate: '{{OPTIONS_CONTRACT_PRICE_GENERATION}} USD',
        fxText: 'par contrat',
      },
    ],
    'pricing::pdp-cards::trade': [
      {
        id: 'core',
        eyebrow: '{{AUM_THRESHOLD_CORE}} d’actifs',
        heading: 'Essentiel',
        subheading: 'Commencez avec des outils financiers simples et à peu de frais.',
        infoLists: [
          {
            items: [
              'Opérations boursières sans commission',
              'Jusqu’à {{INSTANT_DEPOSIT_MAX_CORE}} en dépôts instantanés',
            ],
          },
        ],
        fxRate: '{{USD_ACCOUNT_FX_RATE_CORE}}',
        fxText: 'Frais de change par opération',
      },
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} d’actifs',
        heading: 'Avantage',
        subheading:
          'Tirez le maximum de vos placements grâce à des frais réduits et à des conseils sur mesure.',
        infoLists: [
          {
            items: [
              'Comptes $ US',
              'Aucune commission sur les frais de change pour les opérations aux États-Unis',
              'Jusqu’à {{INSTANT_DEPOSIT_MAX}} en dépôts instantanés',
            ],
          },
        ],
        fxRate: '{{USD_ACCOUNT_FX_RATE_PREMIUM}}',
        fxText: 'Frais de change par opération',
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} d’actifs',
        heading: 'Génération',
        subheading: `Bâtissez votre patrimoine grâce aux conseils avisés de votre équipe-conseil attitrée.`,
        infoLists: [
          {
            items: [
              'Toutes les caractéristiques d’Essentiel et d’Avantage',
              'Plan financier complet',
            ],
          },
        ],
        fxRate: '{{USD_ACCOUNT_FX_RATE_GENERATION}}',
        fxText: 'Frais de change par opération',
      },
    ],
    'pricing::pdp-cards::spend': [
      {
        id: 'core',
        eyebrow: '{{AUM_THRESHOLD_CORE}} d’actifs',
        heading: 'Essentiel',
        subheading: 'Commencez avec des outils financiers simples et à frais modiques',
        infoLists: [
          {
            items: [
              `{{DIY_TRADING_COMMISSION_FEE}} de commission sur la négociation d'actions`,
              `{{MANAGED_INVEST_FEE_CORE}} de frais de gestion sur les comptes de placement gérés`,
              `Une assistance à la clientèle quand vous en avez besoin`,
            ],
          },
        ],
        fxRate: '{{CASH_INTEREST_RATE_CORE}}',
        fxText: 'd’intérêts au compte Cash',
      },
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} d’actifs',
        heading: 'Avantage',
        subheading: `Tirez le meilleur parti de vos placements avec des frais réduits et des conseils personnalisés`,
        infoLists: [
          {
            items: [
              `Tous les avantages de la clientèle Essentiel`,
              `{{MANAGED_INVEST_FEE_PREMIUM}} de frais de gestion sur les comptes de placement gérés`,
              `Des objectifs financiers fixés avec nos spécialistes en gestion de portefeuilles`,
            ],
          },
        ],
        fxRate: '{{CASH_INTEREST_RATE_PREMIUM}}',
        fxText: 'd’intérêts au compte Cash',
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} d’actifs',
        heading: 'Génération',
        subheading: `Bâtissez votre patrimoine grâce à l'expertise de notre équipe de gestionnaires de portefeuille`,
        infoLists: [
          {
            items: [
              `Tous les avantages de la clientèle Avantage`,
              `{{MANAGED_INVEST_FEE_GENERATION_MIN}}*-{{MANAGED_INVEST_FEE_GENERATION_MAX}} de frais de gestion sur les comptes de placement gérés`,
              `Équipe dévouée de gestionnaires de portefeuille`,
            ],
          },
        ],
        fxRate: '{{CASH_INTEREST_RATE_GENERATION}}',
        fxText: 'd’intérêts au compte Cash',
      },
    ],
    'pricing::pdp-cards::crypto': [
      {
        id: 'core',
        eyebrow: '{{AUM_THRESHOLD_CORE}} d’actifs',
        heading: 'Essentiel',
        subheading: 'Commencez avec des outils financiers simples et à peu de frais.',
        infoLists: [
          {
            items: ['Entreposage sécuritaire des pièces', `Dépôts et retraits sans frais`],
          },
        ],
        fxRate: '{{CRYPTO_TRANSACTION_FEE_CORE}}',
        fxText: `Frais d'opérations de`,
      },
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} d’actifs',
        heading: 'Avantage',
        subheading: `Tirez le maximum de vos placements grâce à des frais réduits et à des conseils sur mesure.`,
        infoLists: [
          {
            items: [
              'Toutes les caractéristiques du plan Essentiel',
              `Frais d'opérations par transaction réduits`,
            ],
          },
        ],
        fxRate: '{{CRYPTO_TRANSACTION_FEE_PREMIUM}}',
        fxText: `Frais d'opérations de`,
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} d’actifs',
        heading: 'Génération',
        subheading: `Bâtissez votre patrimoine grâce aux conseils avisés de votre équipe-conseil attitrée.`,
        infoLists: [
          {
            items: [
              'Toutes les caractéristiques d’Essentiel et d’Avantage',
              'Rendement supérieur sur les pièces immobilisées (en staking)',
            ],
          },
        ],
        fxRate: '{{CRYPTO_TRANSACTION_FEE_GENERATION}}',
        fxText: `Frais d'opérations de`,
      },
    ],
    'pricing::pdp-cards::tax': [
      {
        id: 'basic',
        heading: 'De base',
        subheading: 'Toutes les fonctionnalités nécessaires pour faire vos impôts avec confiance.',
        infoLists: [
          {
            items: ['Remboursement maximal garanti', 'Optimisation des crédits et déductions'],
          },
        ],
        fxRate: 'Payez ce que vous voulez',
        fxText: 'Oui, même {{TAX_TIER_PRICE_BASIC}}',
      },
      {
        id: 'plus',
        heading: 'Étendu',
        subheading: `Soutien prioritaire par courriel et protection en cas de vérification pour toutes vos déclarations.`,
        infoLists: [
          {
            items: ['Tous les avantages du forfait de base', 'Protection en cas de vérification'],
          },
        ],
        fxRate: '{{TAX_TIER_PRICE_PLUS}}',
        fxRatePost: '+ taxes',
        fxText: `Gratuit pour la clientèle [Avantage](${PRICING_PATH})`,
      },
      {
        id: 'pro',
        heading: 'Complet',
        subheading: `Améliorez votre expérience par une consultation privée avec un.e fiscaliste*.`,
        infoLists: [
          {
            items: ['Tous les avantages du forfait Étendu', 'Conseils personnalisés'],
          },
        ],
        fxRate: '{{TAX_TIER_PRICE_PRO}}',
        fxRatePost: '+ taxes',
        fxText: `Gratuit pour la clientèle [Génération](${PRICING_PATH})`,
      },
    ],
  },
};
