import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import toSnakeCase from 'to-snake-case';
import { toFieldPath } from '@stackbit/annotations';
import { classNames } from 'src/utils/css';
import { FAQSchema } from 'src/utils/seo';
import { RefreshGrid, RefreshCell, GRID_DEFAULT_CONFIG } from 'src/components/layout/Grid';
import { asSubsection } from 'src/components/layout/Subsection';
import { FluidText, DEFAULT_CONFIG_BY_ELEMENT_TYPE } from 'src/components/common/FluidText';
import { FAQItem } from 'src/components/common/FAQItem';
import { faqHeader_ } from './FAQ.module.scss';

const FAQ = asSubsection('FAQ')(({ id = 'faq', heading, faqs = [], className, children, isCompact, ...rest }) => {
  const headingContent = isString(heading) ? (
    <FluidText
      type="h2"
      {...isCompact ? { all: 'ws-text-2xl-medium' } : { ...DEFAULT_CONFIG_BY_ELEMENT_TYPE.h1, className: faqHeader_ }}
      {...toFieldPath('faqHeading')}
    >
      {heading}
    </FluidText>
  ) : (
    heading
  );

  return (
    <>
      <FAQSchema faqs={faqs} />
      <RefreshGrid
        xs={{ columnGap: 0 }}
        md={{ columnGap: GRID_DEFAULT_CONFIG.gap }}
        className={classNames(className)}
        {...rest}
      >
        <RefreshCell id={toSnakeCase(isString(heading) ? heading : 'faq')} md={isCompact ? 7 : 5}>{headingContent}</RefreshCell>
        <RefreshCell {...isCompact ? { lg: { width: 7, top: 2 } } : { md: { width: 7, left: 6 } }}>
          {faqs.map(({ question, answer, entryId: entryId_ }, index) =>
            (question && answer ? (
              <FAQItem
                id={`item-${id}-${index}`}
                key={question}
                question={question}
                answer={answer}
                entryId={entryId_}
              />
            ) : null)
          )}
          {children}
        </RefreshCell>
      </RefreshGrid>
    </>
  );
});

FAQ.propTypes = {
  id: PropTypes.string.isRequired,
  heading: PropTypes.node.isRequired,
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
  className: PropTypes.string,
};

FAQ.displayName = 'FAQ';

export { FAQ };
