import React from 'react';
import isString from 'lodash/isString';
import { List } from 'src/tapestry/core/list';
import { Markdown } from 'src/tapestry/core/markdown';
import { APP_DOWNLOAD_PATH, CONTACT_FORM_URL, PRICING_PATH } from 'src/utils/urls';
import {
  colorWhite,
  colorText,
  colorSlate95,
  colorLinenBg,
  colorLinenLightBg,
  colorNavyBg,
  colorMidnightBg,
  colorSkyBg98,
} from 'src/styles/exports.module.scss';
import { useTranslation } from 'src/components/contexts/TranslationContext';
import { classNames } from 'src/utils/css';
import { FluidText } from 'src/components/common/FluidText';
import { Background } from 'src/components/common/Background';
import { ArrowLinkButton } from '../ArrowLinkButton';
import { CTAButton } from '../CTAButton';
import {
  variantDefault_,
  variantPDP_,
  variantHome_,
  variantTax_,
  variantPremiumPage_,
  wrapperDefault_,
  wrapperPDP_,
  wrapperTax_,
  premiereEyebrowHome_,
  premiereEyebrowPremiumPage_,
  generationEyebrowHome_,
  generationEyebrowPremiumPage_,
  generationEyebrowPDP_,
  pricingBlock_,
  pricingBlockLower_,
  pricingBlockPostText_,
  featureBlockPdpProduct_,
  featureBlockTax_,
  listBlock_,
  listBlockHome_,
  listBlockHeading_,
  ctaButton_,
  mediaContainer_,
  spacing4_,
  eyebrow_,
  mainCopyBlock_,
  heading_,
  headingWrapper_,
  fxText_,
  currencyTextAlignMiddle_,
} from './PricingCards.module.scss';

export const VARIANTS = {
  default: 'default',
  pdp: 'pdp',
  home: 'home',
  tax: 'tax',
  premiumPage: 'premiumPage',
};

export const CONFIG = {
  variants: {
    default: variantDefault_,
    pdp: variantPDP_,
    home: variantHome_,
    tax: variantTax_,
    premiumPage: variantPremiumPage_,
  },
  core: {
    wrapper: wrapperDefault_,
    bg: colorWhite,
    heading: colorText,
    pillCurrencyText: colorText,
    pillText: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorSlate95,
    benefitButtonLabel: `pricing-cards::benefit::cta::download::label`,
    ctaUrl: APP_DOWNLOAD_PATH,
  },
  premium: {
    wrapper: wrapperDefault_,
    bg: colorWhite,
    heading: colorText,
    pillCurrencyText: colorText,
    pillText: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorLinenBg,
    benefitButtonLabel: `pricing-cards::benefit::cta::contact::label`,
    ctaUrl: CONTACT_FORM_URL,
  },
  generation: {
    wrapper: wrapperDefault_,
    bg: colorWhite,
    heading: colorText,
    pillCurrencyText: colorWhite,
    pillText: colorWhite,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorNavyBg,
    benefitButtonLabel: `pricing-cards::benefit::cta::contact::label`,
    ctaUrl: CONTACT_FORM_URL,
  },
  premiumHome: {
    bg: colorLinenLightBg,
    heading: colorText,
    darkThemeCta: false,
    eyebrow: premiereEyebrowHome_,
    list: listBlockHome_,
    checkmarkColor: colorText,
    benefitButtonLabel: `pricing-cards::benefit::cta::contact::label`,
    ctaUrl: PRICING_PATH,
  },
  generationHome: {
    bg: colorMidnightBg,
    heading: colorWhite,
    darkThemeCta: true,
    eyebrow: generationEyebrowHome_,
    list: listBlockHome_,
    checkmarkColor: colorWhite,
    benefitButtonLabel: `pricing-cards::benefit::cta::contact::label`,
    ctaUrl: PRICING_PATH,
  },
  premiumPremiumPage: {
    bg: colorLinenLightBg,
    heading: colorText,
    eyebrow: premiereEyebrowPremiumPage_,
    ctaUrl: PRICING_PATH,
  },
  generationPremiumPage: {
    bg: '#2A2C32',
    heading: colorWhite,
    darkThemeCta: true,
    eyebrow: generationEyebrowPremiumPage_,
    ctaUrl: PRICING_PATH,
  },
  corePdp: {
    wrapper: wrapperPDP_,
    bg: colorSlate95,
    eyebrow: eyebrow_,
    heading: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorWhite,
    ctaUrl: PRICING_PATH,
  },
  premiumPdp: {
    wrapper: wrapperPDP_,
    bg: colorLinenBg,
    eyebrow: eyebrow_,
    heading: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorWhite,
    ctaUrl: PRICING_PATH,
  },
  generationPdp: {
    wrapper: wrapperPDP_,
    bg: colorMidnightBg,
    eyebrow: generationEyebrowPDP_,
    heading: colorWhite,
    list: listBlock_,
    checkmarkColor: colorWhite,
    pricingBlockBg: colorWhite,
    ctaUrl: PRICING_PATH,
  },
  basicTax: {
    wrapper: wrapperTax_,
    bg: colorWhite,
    heading: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorSkyBg98,
  },
  plusTax: {
    wrapper: wrapperTax_,
    bg: colorWhite,
    heading: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorSkyBg98,
  },
  proTax: {
    wrapper: wrapperTax_,
    bg: colorWhite,
    heading: colorText,
    list: listBlock_,
    checkmarkColor: colorText,
    pricingBlockBg: colorSkyBg98,
  },
};

const PriceBlock = ({
  className,
  background,
  currency,
  currencyColor,
  currencyPostTextColor,
  text,
}) => {
  const currencyElement = (
    <FluidText type="span" all="ws-display-xs" style={{ color: currencyColor }}>
      {currency}
    </FluidText>
  );
  const netDepositsElement = (
    <span className={pricingBlockPostText_} style={{ color: currencyPostTextColor }}>
      {text}
    </span>
  );
  const completePriceElements = (
    <span className={currencyTextAlignMiddle_}>
      {currencyElement}
      {' '}
      {netDepositsElement}
    </span>
  );

  return (
    <div className={className}>
      <Background className={pricingBlock_} background={background}>
        {completePriceElements}
      </Background>
    </div>
  );
};

const CheckList = ({ lists, contentId }) => {
  return lists?.map(list => (
    <div key={`card-list-${list.heading}`} className={CONFIG[contentId].list}>
      {list.heading && (
        <FluidText className={listBlockHeading_} type="h3" all="ws-text-lg-sans">
          {list.heading}
        </FluidText>
      )}
      <List
        textColor={CONFIG[contentId].checkmarkColor}
        iconColor={CONFIG[contentId].checkmarkColor}
        content={list.items}
      />
    </div>
  ));
};

const Eyebrow = ({ text, contentId }) => (
  <FluidText type="p" all="ws-eyebrow-sm" className={CONFIG[contentId].eyebrow}>
    {text}
  </FluidText>
);

const Heading = ({
  isActive,
  heading,
  headingType,
  subheading,
  contentId,
  arrow,
  headingFontConfig = { all: 'ws-display-md' },
}) => {
  return (
    <div className={mainCopyBlock_}>
      <div className={headingWrapper_}>
        <FluidText
          type={headingType}
          {...headingFontConfig}
          className={heading_}
          style={{ color: CONFIG[contentId].heading }}
        >
          {heading}
        </FluidText>
        {CONFIG[contentId].ctaUrl && arrow && (
          <ArrowLinkButton
            transparentBackground
            isActive={isActive}
          />
        )}
      </div>
      {subheading && (
        <FluidText
          type="p"
          all="ws-text-lg"
          style={{ marginTop: spacing4_, color: CONFIG[contentId].heading }}
        >
          {subheading}
        </FluidText>
      )}
    </div>
  );
};

export const PdpCard = ({
  isActive,
  eyebrow,
  heading,
  subheading,
  lists,
  fxRate,
  fxText,
  contentId,
  children,
}) => {
  return (
    <>
      {eyebrow && <Eyebrow text={eyebrow} contentId={contentId} />}
      <Heading
        isActive={isActive}
        heading={heading}
        headingType="h3"
        subheading={subheading}
        contentId={contentId}
        arrow
      />
      <CheckList lists={lists} contentId={contentId} />
      {children}
      <Background
        as="p"
        background={CONFIG[contentId].pricingBlockBg}
        className={featureBlockPdpProduct_}
      >
        <FluidText type="span" all="ws-display-md" className="ws-display-block">
          {fxRate}
        </FluidText>
        <FluidText type="span" all="ws-text-lg" className={classNames(fxText_, 'ws-display-block')}>
          {isString(fxText) ? <Markdown unwrapElements="paragraph" source={fxText} /> : fxText}
        </FluidText>
      </Background>
    </>
  );
};

export const TaxCard = ({
  isActive,
  heading,
  subheading,
  lists,
  fxRate,
  fxRatePost,
  fxText,
  contentId,
}) => {
  return (
    <>
      <Heading
        isActive={isActive}
        heading={heading}
        headingType="h3"
        subheading={subheading}
        contentId={contentId}
        headingFontConfig={{ all: 'ws-display-md-sans' }}
      />
      <CheckList lists={lists} contentId={contentId} />
      <Background className={featureBlockTax_} background={CONFIG[contentId].pricingBlockBg}>
        <div>
          <FluidText type="h4" min="ws-text-2xl-medium" max="ws-text-3xl-medium">
            {fxRate}
            {' '}
            {fxRatePost && (
              <FluidText type="span" all="ws-text-lg">
                {fxRatePost}
              </FluidText>
            )}
          </FluidText>
          <FluidText type="markdown" all="ws-text-lg" className={fxText_}>
            {fxText}
          </FluidText>
        </div>
      </Background>
    </>
  );
};

export const HomeCard = ({ isActive, eyebrow, heading, subheading, lists, contentId, media }) => {
  return (
    <>
      {eyebrow && <Eyebrow text={eyebrow} contentId={contentId} />}
      <Heading
        isActive={isActive}
        heading={heading}
        headingType="h3"
        subheading={subheading}
        contentId={contentId}
        arrow
      />
      <CheckList lists={lists} contentId={contentId} />
      {media && <div className={mediaContainer_}>{media}</div>}
    </>
  );
};

export const BenefitCard = ({
  isActive,
  theme,
  heading,
  subheading,
  lists,
  contentId,
}) => {
  return (
    <>
      <Heading
        isActive={isActive}
        heading={heading}
        headingType="h2"
        subheading={subheading}
        contentId={contentId}
      />
      <PriceBlock
        className={pricingBlockLower_}
        background={CONFIG[contentId].pricingBlockBg}
        currency={useTranslation(`card::content-block::${contentId}::currency`)}
        currencyColor={CONFIG[contentId].pillCurrencyText}
        currencyPostTextColor={CONFIG[contentId].pillText}
        text={useTranslation('card::content-block::post-text')}
        theme={theme}
      />
      <CheckList lists={lists} contentId={contentId} />
      <div>
        <CTAButton className={ctaButton_} variant="secondary" disabled>
          {useTranslation(CONFIG[contentId].benefitButtonLabel)}
        </CTAButton>
      </div>
    </>
  );
};

export const PremiumPageCard = ({
  eyebrow,
  heading,
  subheading,
  contentId,
}) => {
  return (
    <>
      {eyebrow && <Eyebrow text={eyebrow} contentId={contentId} />}
      <Heading
        heading={heading}
        headingType="h2"
        subheading={subheading}
        contentId={contentId}
      />
    </>
  );
};
