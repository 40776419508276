import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toFieldPath, toObjectId } from '@stackbit/annotations';
import { classNames } from 'src/utils/css';
import { Accordion } from 'src/tapestry/core/accordion';
import { Markdown } from 'src/tapestry/core/markdown';
import { useStringWithGlobals } from 'src/components/contexts/TranslationContext';
import { Plus } from 'src/components/common/Icons';
import { useComponentContext } from 'src/components/layout/ComponentContext';
import { EVENT_NAMES, trackAnalyticsEvent } from 'src/services/analytics-service';

import {
  faqItem_,
  itemButton_,
  itemText_,
  itemOpenClose_,
  isOpen_,
  itemAnswer_,
} from './FAQItem.module.scss';
import { FluidText } from '../FluidText';

const FAQItem = ({ id, answer, question, entryId, ...rest }) => {
  const [expanded, setExpanded] = useState(false);
  const componentContext = useComponentContext();
  const questionWithGlobals = useStringWithGlobals(question);
  const answerWithGlobals = useStringWithGlobals(answer);

  const handleClick = () => {
    const isClosing = expanded;
    setExpanded(!expanded);

    trackAnalyticsEvent(
      isClosing ? EVENT_NAMES.FAQ_CLOSED : EVENT_NAMES.FAQ_OPENED,
      {
        label: question,
      },
      componentContext
    );
  };

  const renderQuestionAnnotations = entryId ? toFieldPath('question') : {};
  const renderAnswerAnnotations = entryId ? toFieldPath('answer') : {};

  return (
    <div className={faqItem_} {...toObjectId(entryId)} {...rest}>
      <FluidText type="h3" min="ws-text-lg-medium" max="ws-text-2xl-medium">
        <button
          onClick={handleClick}
          type="button"
          className={itemButton_}
          aria-expanded={expanded}
          aria-controls={id}
        >
          <span className={itemText_} {...renderQuestionAnnotations}>
            {questionWithGlobals}
          </span>
          <Plus className={classNames(itemOpenClose_, expanded && isOpen_)} />
        </button>
      </FluidText>
      <Accordion id={id} isOpen={expanded} duration={0.25}>
        <FluidText
          type="div"
          min="ws-text-lg"
          max="ws-text-2xl"
          className={classNames(itemAnswer_, 'ws-color-muted')}
          {...renderAnswerAnnotations}
        >
          <Markdown source={answerWithGlobals} />
        </FluidText>
      </Accordion>
    </div>
  );
};

FAQItem.propTypes = {
  id: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  entryId: PropTypes.string,
};

export { FAQItem };
