import React from 'react';

export const NumberedBullet = ({
  value,
  textColor = '#fab131',
  strokeColor = '#ffffff',
  fillColor = '#ffffff',
}) => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <rect
          stroke={strokeColor}
          fill={fillColor}
          x="0.5"
          y="0.5"
          width="21"
          height="21"
          rx="11"
        />
        <text fontFamily="futura-pt" fontSize="14" fontWeight="600" fill={textColor}>
          <tspan x="6.71044922" y="16">
            {value}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
